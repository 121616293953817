@font-face {
  font-family: 'Perpetua';
  font-style: normal;
  font-weight: 100;
  src: url('/public/fonts/PER_____.woff'), url('/public/fonts/PER_____.ttf');
}
@font-face {
  font-family: 'Perpetua Bold';
  font-style: normal;
  font-weight: bold;
  src: url('/public/fonts/PERB____.woff'), url('/public/fonts/PERB____.ttf');
}
@font-face {
  font-family: 'Perpetua Italic';
  font-style: italic;
  font-weight: 100;
  src: url('/public/fonts/PERI____.woff'), url('/public/fonts/PERI____.ttf');
}
@font-face {
  font-family: 'Perpetua Bold Italic';
  font-style: italic;
  font-weight: 100;
  src: url('/public/fonts/PERBI___.woff'), url('/public/fonts/PERBI___.ttf');
}
:root {
  --reach-dialog: 1;
}
* {
  margin: 0;
}
html {
  font-size: 16px;
  font-size: 100%;
  height: 100%;
}
html body {
  overflow-x: hidden;
  width: 100%;
  /* typefaces */
  /********************************************
	BREAKPOINT WIDTHS
********************************************/
  /********************************************
	FONTS
********************************************/
  /********************************************
	COLOURS
********************************************/
  padding-bottom: 25px;
}
html body .__wrapper {
  left: 0;
  transition: all linear 0.25s;
  min-height: 22vh;
}
html body .__wrapper--standard {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 4;
  grid-row-end: span 6;
}
html body .__wrapper--nudged {
  left: 0;
}
html body .__wrapper .con-rel {
  position: relative;
}
@media (max-width: 991px) {
  html body .__wrapper {
    width: 100%;
  }
  html body .__wrapper--nudged {
    left: -75%;
  }
}
html body .__wrapper .content {
  margin: 5px auto;
}
html body .__wrapper .content p {
  font-size: 1rem;
}
html body .__wrapper .main-header-component-placeholder {
  background-color: lightgray;
  height: 150px;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
html body .__wrapper .main-header-component-placeholder p {
  font-size: 5rem;
  color: white;
  text-shadow: 0 0 1px #333;
}
html body .__wrapper .panelContent {
  padding: 10px !important;
  border-radius: 5px;
}
html body .__wrapper .panelContent .__list {
  display: block;
  margin-top: -10px;
  padding: 0;
  color: whitesmoke;
}
html body .__wrapper .panelContent .__list .__item {
  list-style-type: none;
  float: none;
  border: none;
  margin-top: 5px;
  font-size: 0.9rem;
}
html body .__wrapper .panelContent .__list .__item a {
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
}
html body .__wrapper .panelContent .__list .__item a:hover {
  text-decoration: underline;
}
html body .__wrapper .panelContent .__list .__item--search a {
  padding-left: 0;
  padding-right: 0;
}
html body .rsgis-dynamic-header .__header,
html body .rsgis-dynamic-header--header {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
html body .rsgis-dynamic-header .__header--centered,
html body .rsgis-dynamic-header--header--centered {
  text-align: center;
}
html body .rsgis-dynamic-header .__header--left,
html body .rsgis-dynamic-header--header--left {
  text-align: left;
}
html body .rsgis-dynamic-header .__header--right,
html body .rsgis-dynamic-header--header--right {
  text-align: right;
}
html body .rsgis-dynamic-header .__header--level6,
html body .rsgis-dynamic-header--header--level6 {
  font-style: italic;
  font-size: 1.25rem;
}
html body .rsgis-dynamic-header .__header--level6 .__text,
html body .rsgis-dynamic-header--header--level6 .__text {
  font-style: italic;
  font-size: 1.25rem;
}
html body .rsgis-dynamic-header .__header--level5,
html body .rsgis-dynamic-header--header--level5 {
  font-size: 1.25rem;
}
html body .rsgis-dynamic-header .__header--level5 .__text,
html body .rsgis-dynamic-header--header--level5 .__text {
  font-size: 1.25rem;
}
html body .rsgis-dynamic-header .__header--level4,
html body .rsgis-dynamic-header--header--level4 {
  font-size: 1.5rem;
}
html body .rsgis-dynamic-header .__header--level4 .__text,
html body .rsgis-dynamic-header--header--level4 .__text {
  font-size: 1.5;
}
html body .rsgis-dynamic-header .__header--level3,
html body .rsgis-dynamic-header--header--level3 {
  font-size: 2rem;
}
html body .rsgis-dynamic-header .__header--level3 .__text,
html body .rsgis-dynamic-header--header--level3 .__text {
  font-size: 2rem;
}
html body .rsgis-dynamic-header .__header--level2,
html body .rsgis-dynamic-header--header--level2 {
  font-size: 2.25rem;
}
html body .rsgis-dynamic-header .__header--level2 .__text,
html body .rsgis-dynamic-header--header--level2 .__text {
  font-size: 2.25rem;
}
html body .rsgis-dynamic-header .__header--level1,
html body .rsgis-dynamic-header--header--level1 {
  font-size: 2.5rem;
}
html body .rsgis-dynamic-header .__header--level1 .__text,
html body .rsgis-dynamic-header--header--level1 .__text {
  font-size: 2.5rem;
}
html body .rsgis-dynamic-header .__header--black,
html body .rsgis-dynamic-header--header--black {
  color: black;
}
html body .rsgis-dynamic-header .__header--green,
html body .rsgis-dynamic-header--header--green {
  color: #18453b;
}
html body .rsgis-dynamic-header .__header--bright-green,
html body .rsgis-dynamic-header--header--bright-green {
  color: #0db14b;
}
html body .rsgis-dynamic-header .__header--neon-green,
html body .rsgis-dynamic-header--header--neon-green {
  color: #d1de3f;
}
html body .rsgis-dynamic-header .__header--grass-green,
html body .rsgis-dynamic-header--header--grass-green {
  color: #94ae4a;
}
html body .rsgis-dynamic-header .__header--light-gray,
html body .rsgis-dynamic-header--header--light-gray {
  color: #99a2a2;
}
html body .rsgis-dynamic-header .__header--dark-gray,
html body .rsgis-dynamic-header--header--dark-gray {
  color: #535054;
}
html body .rsgis-dynamic-header .__header--tan,
html body .rsgis-dynamic-header--header--tan {
  color: #e8d9b5;
}
html body .rsgis-dynamic-header .__header--orange,
html body .rsgis-dynamic-header--header--orange {
  color: #f08521;
}
html body .rsgis-dynamic-header .__header--orange-light,
html body .rsgis-dynamic-header--header--orange-light {
  color: #c89a58;
}
html body .rsgis-dynamic-header .__header--orange-dark,
html body .rsgis-dynamic-header--header--orange-dark {
  color: #cb5a28;
}
html body .rsgis-dynamic-header .__header--teal,
html body .rsgis-dynamic-header--header--teal {
  color: #008183;
}
html body .rsgis-dynamic-header .__header--gray-blue,
html body .rsgis-dynamic-header--header--gray-blue {
  color: #909ab7;
}
html body .rsgis-dynamic-header .__header--magenta,
html body .rsgis-dynamic-header--header--magenta {
  color: #6e005f;
}
html body .rsgis-dynamic-header .__header--all-caps,
html body .rsgis-dynamic-header--header--all-caps {
  text-transform: uppercase;
}
html body .rsgis-dynamic-header .__header--all-caps .__text,
html body .rsgis-dynamic-header--header--all-caps .__text {
  text-transform: uppercase;
}
html body .rsgis-dynamic-header .__header--sub,
html body .rsgis-dynamic-header--header--sub {
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.25;
}
html body .rsgis-dynamic-header .__header--main,
html body .rsgis-dynamic-header--header--main {
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 0;
}
html body .rsgis-dynamic-header .__header--normal-type,
html body .rsgis-dynamic-header--header--normal-type {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  letter-spacing: 0;
  margin-bottom: 0.6875rem;
}
html body .rsgis_logo {
  font-family: 'Perpetua Bold';
  letter-spacing: 2px;
  font-size: 2rem;
  transition: all 0.1s linear;
  display: grid;
  grid-template-columns: auto 34px auto;
  justify-content: flex-start;
  align-content: center;
  position: relative;
}
html body .rsgis_logo span:first-child {
  grid-column: 1;
}
html body .rsgis_logo span:nth-child(2) {
  font-family: "Perpetua Italic";
  font-size: 4rem;
  position: relative;
  top: -26px;
  grid-column: 2;
}
html body .rsgis_logo span:last-child {
  grid-column: 3;
}
html body .rsgis_logo:hover {
  text-shadow: 2px 2px 5px #333;
}
html body .rsgis-logo-img {
  width: 100%;
  height: auto;
  max-width: 150px;
}
html body .footer {
  display: flex;
  justify-content: center;
}
html body .footer img {
  width: 100%;
  height: auto;
}
html body footer {
  margin-top: 25px;
}
html body footer .mobile-hide {
  display: block;
  text-align: right;
}
html body footer .mobile-show {
  display: none;
}
html body footer .border-top {
  border-top: 1px dotted black;
  max-width: 680px;
  width: 110%;
}
@media (max-width: 991px) {
  html body footer .border-top {
    width: 100%;
  }
}
html body footer ul {
  padding-top: 5px;
  margin-top: 5px;
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
  margin: 0;
  display: flex;
  padding: 4px;
}
html body footer ul .bold {
  font-weight: bold;
}
html body footer ul .highlight {
  color: #94ae4a;
  font-weight: bold;
}
html body footer ul li {
  float: left;
  font-size: 0.9rem;
  border-right: 1px solid #18453b;
}
html body footer ul li:last-child {
  border-right: none;
}
html body footer ul li:first-child a {
  padding-left: 0;
}
html body footer ul li a {
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
}
html body footer ul li a:hover {
  text-decoration: underline;
}
html body footer ul.tiny li {
  font-size: 11px;
  padding-right: 5px;
  padding-left: 5px;
  border-right: 1px solid #999;
}
html body footer ul.tiny li:first-child {
  padding-left: 0;
}
html body footer ul.tiny li:last-child {
  border-right: none;
}
html body footer ul.tiny li a {
  padding-left: 0;
  padding-right: 0;
}
html body footer ul.no-margin {
  padding: 0;
  margin: 0;
}
@media (max-width: 991px) {
  html body footer .mobile-show {
    display: block;
    margin-top: 10px;
  }
  html body footer .mobile-hide {
    display: none;
  }
  html body footer ul.modify-desktop {
    display: block;
  }
  html body footer ul.modify-desktop li {
    float: unset;
    padding: 0;
    margin: 0;
    text-align: center;
    border-right: none;
    font-size: 1.6rem;
  }
  html body footer ul.modify-desktop li a {
    padding: 0;
  }
  html body footer ul.tiny {
    display: block;
  }
  html body footer ul.tiny li {
    font-size: 1rem;
  }
}
html body .hamburger {
  display: none;
}
@media (max-width: 991px) {
  html body .hamburger {
    display: block;
  }
}
html body .hamburger .__hamburger {
  background: none;
  border: none;
  padding: 0;
  margin: 0 auto;
  width: 40px;
  height: 50px;
  display: block;
}
html body .hamburger .__hamburger .__line {
  background-color: #18453b;
  width: 80%;
  height: 3px;
  margin: 10px auto;
  transition: all linear 0.1s;
}
html body .hamburger .__hamburger--transparent .__line {
  background-color: rgba(255, 255, 255, 0.5);
}
html body .hamburger .__hamburger--close .__line:nth-child(2) {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari */
  transform: rotate(45deg);
  position: relative;
  top: 7px;
}
html body .hamburger .__hamburger--close .__line:nth-child(3) {
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Safari */
  transform: rotate(-45deg);
  position: relative;
  top: -5px;
}
html body .hamburger .__hamburger--close .__line:nth-child(1) {
  opacity: 0;
}
html body .hamburger .__hamburger--finished .__line:nth-child(1) {
  display: none;
}
html body .rsgis-left-bordered-list .rsgis-list-left-border {
  list-style-type: none;
  border-left: 1px solid #99a2a2;
  padding: 10px;
  font-size: 0.9rem;
  margin-top: 0;
  padding-top: 0;
  min-height: 130px;
}
@media (max-width: 991px) {
  html body .rsgis-left-bordered-list .rsgis-list-left-border {
    min-height: inherit;
    height: auto;
  }
}
html body .rsgis-left-bordered-list .rsgis-list-left-border a {
  text-decoration: none;
  color: #535054;
}
html body .rsgis-left-bordered-list .rsgis-list-left-border a:hover {
  text-decoration: underline;
}
html body .rsgis-left-bordered-list .rsgis-list-left-border li {
  margin: 1rem;
}
html body .mega-menu {
  background: #113d34;
  z-index: 5;
  position: absolute;
  width: 100%;
  left: 0;
  top: 150px;
  grid-template-columns: auto 1200px auto;
  grid-template-rows: 5% auto 5%;
  min-height: 50%;
  color: white;
}
@media (max-width: 1200px) {
  html body .mega-menu {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 1150px) {
  html body .mega-menu {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 992px) {
  html body .mega-menu {
    grid-template-columns: 5% auto 5%;
  }
}
@media (max-width: 767px) {
  html body .mega-menu {
    max-width: 300px;
    position: fixed;
    right: 0;
    left: unset;
    top: 0;
    height: 100%;
    overflow-y: auto;
  }
}
html body .mega-menu h1,
html body .mega-menu h2,
html body .mega-menu h3,
html body .mega-menu h4,
html body .mega-menu h5,
html body .mega-menu h6,
html body .mega-menu p,
html body .mega-menu span {
  color: white;
}
html body .mega-menu .mega-menu-page {
  grid-column-start: 2;
  grid-row-start: 2;
  margin-top: 1rem;
}
html body .mega-menu .__mm-list {
  list-style: none;
  border-right: 1px solid #18453b;
  width: auto;
}
html body .mega-menu .__mm-list li {
  margin-bottom: 2rem;
}
html body .mega-menu .__mm-list--noborder {
  border-right: none;
}
@media (max-width: 767px) {
  html body .mega-menu .__mm-list {
    border-right: none;
  }
}
html body .mega-menu .__mm-link {
  color: white;
  text-decoration: none;
  display: inline;
  font-weight: 600;
  letter-spacing: 1px;
}
html body .mega-menu .__mm-link span {
  display: none;
  height: 1.1rem;
  width: 1.1rem;
  background-color: rgba(0, 0, 0, 0.5);
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  position: relative;
  left: 5px;
  color: #63b3a2;
}
html body .mega-menu .__mm-link:hover,
html body .mega-menu .__mm-link:focus {
  border-bottom: 0.125rem solid #63b3a2;
  color: #fff;
  text-decoration: none;
  padding-bottom: 0.2rem;
}
html body .mega-menu .__mm-link:hover span,
html body .mega-menu .__mm-link:focus span {
  display: inline-flex;
}
html body .mega-menu .__mm-back-btn span {
  color: #63b3a2;
  text-transform: capitalize;
}
html body .mega-menu--visible {
  display: grid;
}
html body .main-msu-nav {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: span 3;
  display: grid;
  grid-template-columns: auto 1200px auto;
  align-items: center;
  background: #18453b;
  z-index: 5;
}
@media (max-width: 1200px) {
  html body .main-msu-nav {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 1150px) {
  html body .main-msu-nav {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 992px) {
  html body .main-msu-nav {
    grid-template-columns: 5% auto 5%;
  }
}
html body .main-msu-nav .__items {
  grid-column-start: 2;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  align-items: center;
  justify-content: flex-start;
  background: #18453b;
  z-index: 5;
  align-self: stretch;
}
html body .main-msu-nav .__items--right {
  justify-content: flex-end;
}
html body .main-msu-nav .__items--left {
  justify-content: flex-start;
}
html body .main-msu-nav .__items--skeleton {
  grid-column-gap: 10px;
}
html body .main-msu-nav .__item {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  align-self: stretch;
}
html body .main-msu-nav .__item .__link {
  display: grid;
  grid-template-rows: 85% 15%;
  color: white;
  text-decoration: none;
  grid-row-start: 1;
  grid-row-end: span 2;
  align-self: stretch;
  grid-column: 1;
}
html body .main-msu-nav .__item .__link span {
  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: span 2;
  align-self: center;
  border-right: 2px solid white;
  padding-right: 10px;
  padding-left: 10px;
  letter-spacing: 0;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1rem;
}
html body .main-msu-nav .__item .__link span.__last {
  border-right: none;
}
html body .main-msu-nav .__item .__link .__hover-line {
  background: #0db14b;
  grid-row-start: 2;
  grid-column: 1;
  width: 100%;
  opacity: 0;
  transition: opacity 0.25s linear;
  width: 80%;
  justify-self: center;
  position: relative;
  top: 100%;
  z-index: 10;
}
html body .main-msu-nav .__item .__link:hover .__hover-line,
html body .main-msu-nav .__item .__link:focus .__hover-line {
  opacity: 1;
}
html body .main-msu-nav .__item .__hover-line {
  opacity: 0;
  transition: opacity 0.25s linear;
  width: 80%;
}
html body .main-msu-nav .__item:hover .__hover-line,
html body .main-msu-nav .__item:focus .__hover-line {
  opacity: 1;
}
html body .main-msu-nav .__item--skeleton {
  background: #99a2a2;
  width: 50px;
  height: 10px;
  border-radius: 5px;
  justify-content: center;
  align-self: auto;
}
@media (max-width: 767px) {
  html body .main-msu-nav {
    display: none;
  }
  html body .main-msu-nav--mobile-active {
    display: grid;
    grid-column-start: 3;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 6;
    width: 95%;
    justify-self: flex-end;
    grid-template-columns: 2% auto 2%;
    grid-template-rows: 50px auto 2%;
  }
  html body .main-msu-nav--mobile-active .__items {
    grid-column-start: 2;
    grid-row: 2;
    justify-self: flex-start;
    align-self: flex-start;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  html body .main-msu-nav--mobile-active .__item {
    margin-bottom: 20px;
    border-bottom: 2px solid #113d34;
    display: grid;
    grid-template-columns: auto 44px;
  }
  html body .main-msu-nav--mobile-active .__item span {
    border-right: 0 !important;
    font-size: 1.25rem !important;
    padding-bottom: 10px;
    grid-column: 1;
  }
  html body .main-msu-nav--mobile-active .__item .__mega-menu-open {
    grid-column: 2;
    justify-self: flex-end;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.1);
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5px;
    color: #63b3a2;
  }
  html body .main-msu-nav--mobile-active .__hover-line {
    display: none;
  }
}
html body .SearchBarContainer {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 110;
  transition: all linear 0.25s;
  left: 0;
}
html body .SearchBarContainer--nudged {
  left: 0;
}
@media (max-width: 991px) {
  html body .SearchBarContainer {
    width: 100%;
  }
  html body .SearchBarContainer--nudged {
    left: -75%;
  }
}
html body .SearchBarContainer--gradient {
  background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, transparent 60%);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 60%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 60%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 60%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
  height: 250px;
  z-index: 90;
}
html body .SearchBarContainer .__desktop {
  padding-top: 0.5rem;
}
@media (max-width: 991px) {
  html body .SearchBarContainer .__desktop {
    display: none;
  }
}
html body .SearchBarContainer .__desktop .input-group {
  display: flex;
  justify-content: flex-end;
}
html body .SearchBarContainer .__desktop input {
  max-width: 255px;
}
html body .SearchBarContainer .__header {
  background-color: #fff;
  transition: all ease-in 0.25s;
  height: 50px;
  opacity: 1;
}
html body .SearchBarContainer .__header--transparent {
  background-color: rgba(255, 255, 255, 0);
}
html body .SearchBarContainer .__header--hidden {
  opacity: 0;
}
html body .SearchBarContainer .__header #MSULOGO {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 991px) {
  html body .SearchBarContainer .__header #MSULOGO {
    align-items: flex-end;
  }
}
html body .SearchBarContainer .__header .__logo {
  position: relative;
  bottom: 7px;
}
@media (max-width: 991px) {
  html body .SearchBarContainer .__header .__logo {
    width: 100%;
    height: auto;
  }
}
html body .SearchBarContainer .__header .__logo--hidden {
  display: none;
}
html body .SearchBarContainer .__header .SearchButton {
  display: none;
}
html body .SearchBarContainer .__header .__input {
  margin-top: 8px;
  float: right;
  width: 100%;
  max-width: 320px;
}
html body .SearchBarContainer .__header .__input input {
  font-size: 0.9rem;
}
html body .social-list {
  list-style-type: none;
  margin: 5px auto;
  width: 150px;
  padding: 0;
}
html body .social-list li {
  display: inline-block;
  margin: 14px;
}
html body .social-list li a {
  display: block;
  width: 44px;
  height: 44px;
  font-size: 2.3rem;
  /*border: 2px solid $msu-green;*/
  line-height: 44px;
  text-align: center;
  border-radius: 44px;
  color: #6d6d6d;
  background-color: white;
  transition: all 0.25s linear;
}
html body .social-list li a:hover {
  background-color: #8de9d4;
}
html body .social-list li a i {
  position: relative;
  top: 1px;
}
html body body {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
}
html body h1 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  color: #18453b;
}
main html body h1 {
  letter-spacing: 0rem;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #18453b;
}
@media screen and (max-width: 414px) {
  main html body h1 {
    font-size: 1.75rem;
  }
}
html body h2 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #18453b;
}
main html body h2 {
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  letter-spacing: 0;
  font-size: 1.625rem;
  margin-bottom: 0.6875rem;
}
html body h3 {
  font-size: 1.3rem;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  letter-spacing: 0;
  font-weight: 700;
  color: #18453b;
}
main html body h3 {
  font-size: 1.375rem;
}
html body h4 {
  font-size: 1.1rem;
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  color: #18453b;
}
html body ol,
html body p,
html body ul,
html body dl {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 0.9375rem;
  color: #444;
}
html body ul {
  list-style-type: disc;
}
html body ul ul {
  list-style-type: circle;
}
html body ul ul ul {
  list-style-type: square;
}
html body ul ul ul ul {
  list-style-type: disc;
}
html body ul ul ul ul ul {
  list-style-type: circle;
}
html body ul ul ul ul ul ul {
  list-style-type: square;
}
html body ol {
  list-style-type: decimal;
}
html body ol ol {
  list-style-type: lower-alpha;
}
html body ol ol ol {
  list-style-type: lower-roman;
}
html body ol ol ol ol {
  list-style-type: decimal;
}
html body ol ol ol ol ol {
  list-style-type: lower-alpha;
}
html body ol ol ol ol ol ol {
  list-style-type: lower-roman;
}
html body dl dt {
  margin-bottom: 0;
}
html body dl dd {
  margin-bottom: 0.3rem;
}
html body main div > ol,
html body main div > ul {
  padding-left: 22px;
}
html body a {
  color: #18453b;
  outline: 0;
  text-decoration: underline;
}
html body a:visited {
  color: #18453b;
  text-decoration: none;
  outline: 0;
}
html body a:focus,
html body a:hover {
  color: #18453b;
  text-decoration: none;
}
html body p a {
  line-height: inherit;
}
html body p a:visited {
  line-height: inherit;
}
html body .lead {
  font-size: 115%;
}
html body :focus {
  outline: -webkit-focus-ring-color auto 5px !important;
}
html body :-moz-focusring {
  outline: 2px dotted;
}
html body hr {
  max-width: 100%;
  width: 100%;
  height: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  margin: 1.25rem auto;
  clear: both;
}
html body img.float-right {
  margin: 0 0 15px 15px !important;
}
html body img.float-left {
  margin: 0 15px 15px 0 !important;
}
html body .rsgis-featured .__header {
  display: flex;
}
html body .rsgis-featured .__header div {
  font-size: 1.35rem;
  font-weight: 700;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 823px) {
  html body .rsgis-featured .__header div {
    font-size: 1.2rem;
  }
}
html body .rsgis-featured .__header .__icon {
  width: 30px;
  height: auto;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}
html body .rsgis-featured .__header .__icon--news {
  background-image: url('https://cdn.cabs.msu.edu/framework/images/ico-news-green.svg');
}
html body .rsgis-featured .__header .__icon--event {
  background-image: url("https://cdn.cabs.msu.edu/framework/images/ico-events-green.svg");
}
html body .rsgis-featured .__header .__green {
  color: #18453b;
}
html body .rsgis-featured .__header .__green a {
  text-decoration: none;
  color: #18453b;
}
html body .rsgis-featured .__header .__gray {
  color: #535054;
}
html body .rsgis-featured .__header .__gray a {
  text-decoration: none;
  color: #535054;
}
html body .rsgis-featured .__feature {
  margin: 15px auto;
}
@media (max-width: 991px) {
  html body .rsgis-featured .__feature {
    border-bottom: 1px solid #eee;
  }
}
html body .rsgis-featured .__feature .__event {
  height: 173px;
}
@media (max-width: 991px) {
  html body .rsgis-featured .__feature .__event {
    height: 147px;
  }
}
html body .rsgis-featured .__feature .__event .__link a {
  font-size: 1.5rem;
  font-family: "Times New Roman", Times, serif;
  font-weight: normal;
  text-decoration: none;
}
@media (max-width: 991px) {
  html body .rsgis-featured .__feature .__event .__link a {
    font-size: 1.2rem;
  }
}
html body .rsgis-featured .__feature .__event--news .__image {
  width: 165px;
  height: 173px;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  object-position: left;
}
@media (max-width: 991px) {
  html body .rsgis-featured .__feature .__event--news .__image {
    width: 140px;
    height: 147px;
  }
}
html body .rsgis-featured .__feature .__event--news .__date {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #757575;
  font-size: 1rem;
  letter-spacing: 1px;
}
html body .rsgis-featured .__feature .__event--event .__date {
  width: 165px;
  height: 173px;
  width: 10rem;
  height: 10rem;
  border: 5px solid #99a2a2;
  display: block;
  text-align: center;
}
html body .rsgis-featured .__feature .__event--event .__date .__item {
  text-transform: uppercase;
  font-weight: 700;
  color: #757575;
  font-size: 1.3rem;
  letter-spacing: 1px;
  position: relative;
}
html body .rsgis-featured .__feature .__event--event .__date .__item:nth-child(1) {
  top: 8px;
}
html body .rsgis-featured .__feature .__event--event .__date .__item:nth-child(2) {
  font-size: 3rem;
  color: #18453b;
}
@media (max-width: 991px) {
  html body .rsgis-featured .__feature .__event--event .__date {
    width: 140px;
    height: 147px;
  }
  html body .rsgis-featured .__feature .__event--event .__date .__item:nth-child(2) {
    bottom: 8px;
  }
  html body .rsgis-featured .__feature .__event--event .__date .__item:nth-child(3) {
    bottom: 18px;
  }
}
html body .rsgis-featured .__feature .carousel .carousel-indicators {
  position: relative;
  justify-content: left;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  bottom: 0;
}
html body .rsgis-featured .__feature .carousel .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: #99a2a2;
  border-radius: 15px;
}
html body .rsgis-featured .__feature .carousel .carousel-indicators li.active {
  background-color: #535054;
}
html body .msu-grid {
  display: grid;
  grid-template-rows: 50px 50px 50px 17.5% auto;
  grid-template-columns: auto 1200px auto;
  height: 100%;
  width: 100%;
}
@media (max-width: 1200px) {
  html body .msu-grid {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 1150px) {
  html body .msu-grid {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 992px) {
  html body .msu-grid {
    grid-template-columns: 5% auto 5%;
  }
}
@media (max-width: 767px) {
  html body .msu-grid {
    grid-template-columns: 5% auto 300px;
  }
}
html body .msu-grid .transparent {
  background: linear-gradient(rgba(0, 0, 0, 0.6) 0, transparent 60%);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 60%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 60%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
  z-index: 3;
  grid-row-start: 1;
  grid-row-end: span 4;
  grid-column-start: 1;
  grid-column-end: span 3;
}
@media (max-width: 767px) {
  html body .msu-grid .transparent {
    grid-column-end: span 4;
  }
}
html body .msu-grid .main-msu-title {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: span 3;
  z-index: 5;
  display: grid;
  grid-template-columns: auto 1200px auto;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  html body .msu-grid .main-msu-title {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 1150px) {
  html body .msu-grid .main-msu-title {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 992px) {
  html body .msu-grid .main-msu-title {
    grid-template-columns: 5% auto 5%;
  }
}
@media (max-width: 767px) {
  html body .msu-grid .main-msu-title {
    grid-template-columns: 5% auto 5%;
    grid-column-start: 1;
    grid-column-end: span 4;
  }
}
html body .msu-grid .main-msu-title a {
  color: white;
  text-decoration: none;
  grid-column-start: 2;
}
html body .msu-grid .main-msu-title a:hover {
  color: #e6e3e1;
  text-decoration: none;
}
html body .msu-grid .main-msu-title--standard {
  background: #fff;
}
html body .msu-grid .main-msu-title--standard a {
  color: #18453b;
}
html body .msu-grid .main-msu-title--standard a:hover {
  color: #0db14b;
  text-shadow: none;
}
html body .msu-grid .main-msu-header {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: span 3;
  z-index: 5;
  display: grid;
  grid-template-columns: auto 1200px auto;
  align-items: center;
}
@media (max-width: 1200px) {
  html body .msu-grid .main-msu-header {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 1150px) {
  html body .msu-grid .main-msu-header {
    grid-template-columns: 10% auto 10%;
  }
}
@media (max-width: 992px) {
  html body .msu-grid .main-msu-header {
    grid-template-columns: 5% auto 5%;
  }
}
@media (max-width: 767px) {
  html body .msu-grid .main-msu-header {
    grid-template-columns: 5% auto 5%;
  }
}
@media (max-width: 767px) {
  html body .msu-grid .main-msu-header {
    grid-template-columns: 5% auto 50px 5%;
    grid-column-start: 1;
    grid-column-end: span 4;
  }
}
html body .msu-grid .main-msu-header--standard {
  background: #fff;
}
html body .msu-grid .main-msu-header img {
  grid-column-start: 2;
  grid-row: 1;
}
@media (max-width: 767px) {
  html body .msu-grid .main-msu-header img {
    max-width: 245px;
  }
}
html body .msu-grid .main-msu-header--sticky {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 50px;
  left: 0;
  top: 0;
}
html body .msu-grid .msu-nav-mobile-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: center;
  z-index: 2;
  grid-column: 3;
}
html body .msu-grid .msu-nav-mobile-btn .__line {
  width: 90%;
  height: 3px;
  background-color: white;
  border-radius: 3px;
}
html body .msu-grid .msu-nav-mobile-btn--standard .__line {
  background-color: #18453b;
}
html body .msu-grid .msu-nav-mobile-btn--green .__line {
  background-color: #18453b;
}
html body .msu-grid .msu-nav-mobile-btn--sticky .__line {
  background-color: #18453b;
}
@media (min-width: 767px) {
  html body .msu-grid .msu-nav-mobile-btn {
    display: none;
  }
}
@media (max-width: 767px) {
  html body .msu-grid .msu-nav-mobile-btn {
    display: flex;
  }
}
html body .msu-grid .msu-nav-mobile-btn--clicked {
  background: #18453b;
  border-radius: 25px;
  width: 40px;
  height: 40px;
  grid-column: 2;
  grid-row: 1;
  justify-self: flex-start;
}
html body .msu-grid .msu-nav-mobile-btn--clicked .__times {
  font-size: 1.5rem;
  color: white;
}
html body .FAText {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80px 25px;
  justify-items: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
html body .FAText i {
  font-size: 3rem;
  color: white;
}
html body .FAText div {
  background: #0db14b;
  align-self: center;
  justify-self: center;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
html body .FAText span {
  font-weight: bold;
  color: #18453b;
  font-size: 1rem;
}
html body .column-icon-header-text {
  text-align: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 75px auto;
}
html body .column-icon-header-text .__icon {
  font-size: 4rem;
  color: #0db14b;
}
html body .column-icon-header-text .__text {
  text-align: justify;
}
html body .column-icon-header-text .__header {
  color: #18453b;
  font-weight: bold;
  font-size: 1.4rem;
  align-self: center;
}
html body .column-header-image {
  text-align: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 250px;
}
html body .column-header-image img {
  width: 100%;
  height: auto;
  max-width: 310px;
}
html body .column-header-image .__header {
  font-weight: bold;
  font-size: 1.25rem;
  align-self: center;
}
@media print {
  html body body {
    display: none;
    height: 0vh;
    width: 0vw;
    overflow: hidden;
  }
  html body .not-printible {
    display: none !important;
  }
  html body .page-to-print {
    display: block !important;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 20px;
    line-height: 18px;
    z-index: 1000;
  }
  html body .page-to-print a {
    text-decoration: none;
  }
  html body p {
    font-size: 1.5rem;
  }
}
html body .btn-text-fix.btn-outline-primary {
  color: white;
}
html body .btn-text-fix.btn-outline-primary:hover {
  color: white;
}
html body .cust-footer {
  background-color: #f1f1f1;
  margin-top: 50px;
}
html body .cust-bottom-social {
  background-color: #6d6d6d;
  min-height: 55px;
}
html body .cust-footer-info-list {
  padding-top: 0;
  margin-top: 0;
}
@media (min-width: 1200px) {
  html body .cust-container {
    width: 1278px !important;
  }
}
@media (min-width: 1920px) {
  html body .cust-container {
    width: 1918px !important;
  }
}
html body .standard-image {
  width: 100%;
  height: auto;
}
html body .messages {
  position: relative;
  z-index: 1;
  top: -52px;
  left: 170px;
  width: 62%;
  margin-bottom: -70px;
}
html body .messages.mobileMessages {
  top: 0px;
  left: 0px;
  width: 100%;
  margin-bottom: -20px;
}
html body .messages.StandardMessages {
  top: 0px;
  left: 0px;
  width: 100%;
  margin-bottom: -20px;
}
html body .cust-hide {
  display: none;
}
html body .cust-link {
  text-decoration: none;
}
html body .cust-link:hover {
  text-decoration: underline;
}
html body .cust-btn {
  text-decoration: none;
  color: white !important;
}
html body .cust-select {
  height: calc(3.25rem + 2px) !important;
  min-width: 73px;
}
html body .cust-select.cust-select-weather {
  max-width: 365px;
}
html body .cust-select.cust-select-stations {
  max-width: 160px;
}
html body .cust-label {
  font-size: 1.8rem;
  padding-top: 5px;
}
html body .click-me {
  text-decoration: underline;
}
html body .click-me:hover {
  text-decoration: none;
}
html body .breadcrumbs-group {
  font-size: 1rem;
  padding-top: 15px;
}
html body .breadcrumbs-group .__breadcrumb {
  text-decoration: none;
  color: #18453b !important;
  padding-right: 6px;
}
html body .breadcrumbs-group .__breadcrumb::after {
  content: "/";
  padding-left: 6px;
  display: inline-table;
}
html body .breadcrumbs-group .__breadcrumb:hover {
  text-decoration: underline;
}
html body .dashboard-piece {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
html body .headers {
  text-decoration: none;
}
html body .headers:hover {
  text-decoration: underline;
}
html body .WeatherMapAlert {
  margin-bottom: -5px !important;
  z-index: 100;
}
html body button.accordion {
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
html body button.accordion.small {
  width: 20%;
  min-width: 175px;
}
html body button.accordion.accordion-mobile {
  width: 100%;
}
html body button.accordion:hover {
  background-color: #ddd;
}
html body button.accordion::after {
  content: '\f055';
  font-family: "fontawesome";
  font-size: 14px;
  float: right;
}
html body button.accordion.is-open {
  background-color: #ddd;
}
html body button.accordion.is-open::after {
  content: "\f056";
}
html body button.QLaccordion {
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
html body button.QLaccordion.small {
  width: 100%;
  min-width: 200px;
}
html body button.QLaccordion:hover {
  background-color: #ddd;
}
html body button.QLaccordion::after {
  content: '\f055';
  font-family: "fontawesome";
  font-size: 14px;
  float: right;
}
html body button.QLaccordion.is-open {
  background-color: #ddd;
}
html body button.QLaccordion.is-open::after {
  content: "\f056";
}
html body .quicklinks-text {
  margin-bottom: -15px;
}
html body .quicklinks-text a {
  text-decoration: none;
}
html body .quicklinks-text a:hover {
  text-decoration: underline;
}
html body .admin-toolbar {
  padding: 0 20px;
  background-color: whitesmoke;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
html body .quickLinksContainer {
  max-width: 255px;
  position: relative;
  z-index: 100;
}
html body .quickLinksContainer .quickLinks .QL-content {
  width: 100%;
  min-width: 200px;
  padding: 0 20px;
  background-color: whitesmoke;
  border-style: solid;
  border-color: #18453b;
  border-width: 0px;
  max-height: 0;
  overflow: hidden;
}
html body .toolbar-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 480px;
}
html body .admin-toolbar-navbar {
  min-height: 40px;
  background-color: #18453b;
  padding-top: 9px;
  margin-left: -20px;
  margin-right: -20px;
}
html body .admin-toolbar-navbar .__list {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
  display: flex;
}
html body .admin-toolbar-navbar .__list .__item {
  float: left;
  font-size: 0.9rem;
  border-right: 1px solid #99a2a2;
}
html body .admin-toolbar-navbar .__list .__item:last-child {
  border-right: none;
}
html body .admin-toolbar-navbar .__list .__item.dropdown:hover .subLevel {
  margin: -4px 0 0 !important;
  display: block;
  min-width: 150px;
  background-color: #18453b;
  font-size: 1.3rem;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}
html body .admin-toolbar-navbar .__list .__item.dropdown:hover .subLevel:hover {
  display: block;
}
html body .admin-toolbar-navbar .__list .__item--active {
  font-weight: bold;
}
html body .admin-toolbar-navbar .__list .__item a {
  text-decoration: none;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}
html body .admin-toolbar-navbar .__list .__item a:hover {
  text-decoration: underline;
}
html body .MRN {
  margin-top: 30px !important;
  margin-bottom: -45px !important;
}
html body .MRN .MobileRightNav .MobileAccordion {
  padding: 0 20px;
  background-color: whitesmoke;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
html body .MRN .MobileRightNav .MobileAccordion .__list {
  display: block;
  padding: 0;
  color: whitesmoke;
  overflow-y: auto;
  max-height: 480px;
}
html body .MRN .MobileRightNav .MobileAccordion .__list .__item {
  float: none;
  border: none;
  margin-top: 5px;
  font-size: 0.9rem;
}
html body .MRN .MobileRightNav .MobileAccordion .__list .__item a {
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
}
html body .MRN .MobileRightNav .MobileAccordion .__list .__item a:hover {
  text-decoration: underline;
}
html body .MobileLegend {
  margin-top: 25px;
  margin-bottom: -35px;
}
html body .MobileWeatherData {
  margin-bottom: -125px;
}
html body .stationList {
  padding-bottom: 15px;
}
html body .stationList .stationItem a {
  text-decoration: none;
}
html body .stationList .stationItem a:hover {
  text-decoration: underline;
}
html body .edit-cons-comm-crop {
  height: 150px;
  overflow: hidden;
}
html body .connection-checkboxes {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 4px;
}
html body .connections-tab {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
html body .goToEdits {
  max-height: 266px;
  overflow-x: hidden;
  overflow-y: auto;
}
html body .goToEdits p {
  font-size: 2rem !important;
}
html body .goToEdits p a {
  text-decoration: none;
}
html body .goToEdits p a:hover {
  text-decoration: underline;
}
html body .TableHolder h2 {
  font-size: 3rem;
}
html body .TableHolder div div {
  font-size: 1.6rem;
}
html body .TableHolder .table {
  border: 1px solid #d4d4d4 !important;
  font-size: 1.6rem;
}
html body .TableHolder .table div div p {
  font-size: 1.6rem;
}
html body .TableHolder .table .odd {
  background-color: #e7ffed;
}
html body .TableHolder .table .odd:hover {
  background-color: #fff087;
}
html body .TableHolder .table .even {
  background-color: white;
}
html body .TableHolder .table .even:hover {
  background-color: #fff087;
}
html body .TableHolder .table .clicked {
  background-color: yellow;
}
html body .TableHolder .table .clicked:hover {
  background-color: #d2c202;
}
html body h2.rem3 {
  font-size: 3rem;
}
html body .ShowImage {
  width: 825px;
  height: 300px;
  overflow: hidden;
}
html body .ShowImage img {
  width: 100% ;
}
html body .IndexImage {
  width: 365px;
  height: 150px;
  overflow: hidden;
}
html body .IndexImage img {
  width: 100%;
}
html body .SmallIndexImage {
  width: 275px;
  height: 130px;
  overflow: hidden;
}
html body .SmallIndexImage img {
  width: 100%;
}
html body .MobileIndexImage {
  height: 140px;
  overflow: hidden;
}
html body .MobileIndexImage img {
  width: 100%;
}
html body .StationImage {
  width: 335px;
  height: 225px;
  overflow: hidden;
}
html body .StationImage img {
  width: 100% ;
}
html body .EditImage {
  width: 520px;
  height: 280px;
  overflow: hidden;
}
html body .EditImage img {
  width: 100% ;
}
html body .EditStationImage {
  width: 400px;
  height: 300px;
  overflow: hidden;
}
html body .EditStationImage img {
  width: 100% ;
}
html body .viewImagesIndex {
  width: 375px;
  height: 220px;
  overflow: hidden;
}
html body .viewImagesIndex img {
  width: 100% ;
}
html body .deleteImage {
  position: relative;
  margin-bottom: -35px;
  padding-left: 336px;
}
html body .header-image {
  width: 1920px;
  height: 175px;
  overflow: hidden;
}
html body .header-image img {
  width: 100% ;
}
html body .headerImage {
  width: 100%;
  height: 175px;
}
html body .weatherMapWatchBtn {
  margin-top: 25px;
}
html body .weatherLegend {
  height: 275px;
}
html body .webAddress {
  overflow: auto;
}
html body .truncate {
  width: 100%;
  height: 20px;
  overflow: hidden;
}
html body .truncate p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
html body .esri-view {
  height: 500px;
  width: 100%;
}
html body .colAdjustment .col-md {
  margin-left: -15px;
}
html body .colAdjustment .col-8 {
  margin-left: -15px;
}
html body .flex-items-evenly {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body .flex-items-evenly.align-baseline {
  align-items: baseline;
}
html body .BROKEN_branded-accordions {
  padding-left: 0;
  margin-top: 25px;
  margin-bottom: 35px;
  list-style-type: none;
  background: #fefefe;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  border-radius: 0;
  margin-left: 0;
  font-size: 0.9375rem;
}
html body .BROKEN_branded-accordions .accordion-item {
  font-size: inherit;
}
html body .BROKEN_branded-accordions .accordion-item.is-active {
  font-size: 0.875rem;
  color: #444;
  font-weight: 700;
}
html body .BROKEN_branded-accordions .accordion-item.is-active .accordion-title:before {
  content: '–';
}
html body .BROKEN_branded-accordions .accordion-item.is-active .accordion-content {
  display: block !important;
}
html body .BROKEN_branded-accordions .accordion-item .accordion-title {
  font-size: 0.875rem;
  text-decoration: none;
}
html body .BROKEN_branded-accordions .accordion-item .accordion-title:before {
  content: '+';
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -0.5rem;
}
html body .BROKEN_branded-accordions .accordion-item .accordion-content {
  padding: 1rem;
  display: none;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fefefe;
}
html body .branded-accordion .accordion-header {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 15px;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  font-size: 0.9375rem;
  color: #444;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}
html body .branded-accordion .accordion-header:hover {
  background-color: #e6e6e6;
}
html body .branded-accordion .accordion-header.is-active {
  font-size: 0.875rem;
  color: #444;
  font-weight: 700;
  background-color: #e6e6e6;
}
html body .branded-accordion .accordion-header.is-active:hover {
  background-color: #fefefe;
}
html body .branded-accordion .dashboard-card-content {
  padding: 0.01em 16px;
  border: 1px solid #e6e6e6;
  border-top: 0;
}
html body .branded-accordion .dashboard-card-content.closed-card {
  border: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  max-height: 0px;
  overflow: hidden;
}
html body .dashboard-card {
  border: 1px solid lightgray;
  border-top: none;
  margin-bottom: 2rem;
}
html body .dashboard-card.no-margin-bottom {
  margin-bottom: 0px !important;
}
html body .dashboard-card.card-in-card {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
html body .dashboard-card .no-margin-bottom {
  margin-bottom: 0px !important;
}
html body .dashboard-card .dashboard-card-content {
  padding: 0.01em 16px;
}
html body .dashboard-card .dashboard-card-content h2 {
  color: #fff !important;
  margin-top: 1rem;
}
html body .dashboard-card .dashboard-card-content h3 {
  color: #fff !important;
  margin-top: 1rem;
}
html body .dashboard-card .dashboard-card-content h4 {
  font-size: 1rem;
}
html body .dashboard-card .dashboard-card-content .black-text {
  color: black !important;
}
html body .dashboard-card .dashboard-card-content i {
  font-size: 2em;
}
html body .dashboard-card .dashboard-card-content.flex-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body .dashboard-card .dashboard-card-content.clickable {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}
html body .dashboard-card .dashboard-card-content.border-blue {
  border-style: solid;
  border-color: #008183;
}
html body .dashboard-card .dashboard-card-content.light-blue-card {
  background-color: #00a0a2;
}
html body .dashboard-card .dashboard-card-content.blue-card {
  background-color: #008183;
}
html body .dashboard-card .dashboard-card-content.msugreen-card {
  background-color: #18453b;
}
html body .dashboard-card .dashboard-card-content.msugreen-card i {
  color: white;
}
html body .dashboard-card .dashboard-card-content.green-card {
  background-color: #0DB14B;
}
html body .dashboard-card .dashboard-card-content.light-green-card {
  background-color: #94AE4A;
}
html body .dashboard-card .dashboard-card-content.y-green-card {
  background-color: #D1DE3F;
}
html body .dashboard-card .dashboard-card-content.red-card {
  background-color: #e42e35;
}
html body .dashboard-card .dashboard-card-content.magenta-card {
  background-color: #6E005f;
}
html body .dashboard-card .dashboard-card-content.grey-card {
  background-color: #535054;
}
html body .dashboard-card .dashboard-card-content.off-white-card {
  background-color: #e2e2e2;
}
html body .dashboard-card .dashboard-card-content.whitesmoke-card {
  background-color: whitesmoke;
}
html body .dashboard-card .dashboard-card-content.white-card {
  background-color: white;
}
html body .dashboard-card .dashboard-card-content .padding-for-group {
  padding-top: 15px;
  padding-bottom: 20px;
}
html body .dashboard-card .dashboard-card-content.xl-text-card {
  max-height: 35rem;
  overflow-y: auto;
}
html body .dashboard-card .dashboard-card-content.lg-text-card {
  max-height: 31rem;
  overflow-y: auto;
}
html body .dashboard-card .dashboard-card-content.md-text-card {
  max-height: 20rem;
  overflow-y: auto;
}
html body .dashboard-card .dashboard-card-content.sm-text-card {
  max-height: 10rem;
  overflow-y: auto;
}
html body .dashboard-card .dashboard-card-content.closed-card {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  max-height: 0px;
  overflow: hidden;
}
html body .dashboard-card .dashboard-card-content .dark-text {
  color: black !important;
}
html body .dashboard-card .dashboard-card-content .white-letters {
  color: #fff !important;
}
html body .dashboard-card .dashboard-card-content .spacing-fix {
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
}
html body .dashboard-card .dashboard-card-content .smaller-i {
  font-size: 1em;
}
html body .dashboard-card .dashboard-card-content .title-piece {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body .dashboard-card .dashboard-card-content .title-piece i {
  font-size: 1.3em;
  cursor: pointer;
}
html body .dashboard-card .dashboard-card-content .title-piece.byitself {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
html body .dashboard-card .dashboard-card-content .row-piece p {
  margin-bottom: -1.5rem;
}
html body .dashboard-card .dashboard-card-content .link-piece {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
html body .dashboard-card .dashboard-card-content .graph-img {
  width: 100%;
}
html body .profilePicture {
  width: 100%;
  margin-bottom: 20px;
}
html body .profilePicture.noMargin {
  margin-bottom: 0px !important;
}
html body .profilePicture.lowWidth {
  width: 35% !important;
}
html body .cal-table table,
html body .cal-table th,
html body .cal-table td {
  border: 1px solid black;
}
html body .cal-table thead {
  color: white;
  background: #18453b;
}
html body .cal-table .yellow-highlight {
  background-color: #fff087 !important;
}
html body .result-table-holder {
  overflow-x: auto;
}
html body .result-table-holder .result-table {
  overflow: auto;
  padding-left: 154px;
}
html body .result-table-holder .result-table th {
  background-color: #18453b;
  color: white;
  white-space: nowrap;
}
html body .result-table-holder .result-table tr:nth-child(odd) {
  background-color: #ffffff;
}
html body .result-table-holder .result-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
html body .result-table-holder .result-table tr:hover {
  background-color: #fff087;
}
html body .result-table-holder .result-table .clicked {
  background-color: yellow !important;
}
html body .result-table-holder .result-table .clicked:hover {
  background-color: #d2c202 !important;
}
html body .result-table-holder .result-table .hideTD {
  display: none;
}
html body .result-table-holder .result-table .freeze {
  position: absolute;
  width: 154px;
  left: 0;
  background-color: inherit;
}
html body .result-table-holder .result-table .freeze.f-header {
  background-color: #0DB14B;
}
html body .imgPlaceHolder {
  background-color: gray;
  width: 100vw;
}
html body .imgPlaceHolder.IPH-tall {
  height: 50vh;
}
html body .imgPlaceHolder.IPH-short {
  height: 15vh;
}
html body .custom-btn-white {
  background-color: #eeeeee;
  border-color: black;
  color: black !important;
  text-decoration: none !important;
  font-weight: bold;
}
html body .custom-btn-white:hover {
  background-color: white;
}
html body .custom-btn-red {
  background-color: #e30202;
  border-color: black;
  color: white !important;
  text-decoration: none !important;
  font-weight: bold;
}
html body .custom-btn-red:hover {
  background-color: red;
}
html body .person-profile {
  text-decoration: none !important;
}
html body .person-profile .__name {
  border-bottom: 1px solid #d3f8f0;
  padding-bottom: 10px;
}
html body .person-profile .no-margin-bottom {
  margin-bottom: 0px !important;
}
html body ul.branded {
  list-style: none;
  /* Remove default bullets */
}
html body ul.branded li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #0db14b;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
  font-size: 1rem;
}
html body .btn-msu {
  color: #fff;
  font-weight: 700;
  background-color: #18453b;
  border: none;
  transition: all linear 0.25s;
  text-decoration: none;
}
html body .btn-msu:visited {
  color: white;
}
html body .btn-msu:hover {
  color: #6cb7a5;
}
html body .btn-msu:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 183, 165, 0.5);
  color: #6cb7a5;
}
html body .btn-msu:active {
  transition: none;
  background-color: #0db14b;
  color: white;
}
html body ol li,
html body ul li {
  padding: unset;
}
html body .control-arrow {
  z-index: 4 !important;
}
html body .uasTextSpaceFix {
  margin-top: 111px;
}
@media (max-width: 823px) {
  html body .uasTextSpaceFix {
    margin-top: 175px;
  }
}
@media (max-width: 823px) {
  html body .accrodionSection {
    max-width: 791px;
  }
}
@media (max-width: 812px) {
  html body .accrodionSection {
    max-width: 780px;
  }
}
@media (max-width: 736px) {
  html body .accrodionSection {
    max-width: 704px;
  }
}
@media (max-width: 731px) {
  html body .accrodionSection {
    max-width: 699px;
  }
}
@media (max-width: 667px) {
  html body .accrodionSection {
    max-width: 635px;
  }
}
@media (max-width: 640px) {
  html body .accrodionSection {
    max-width: 608px;
  }
}
@media (max-width: 600px) {
  html body .accrodionSection {
    max-width: 568px;
  }
}
@media (max-width: 568px) {
  html body .accrodionSection {
    max-width: 535px;
  }
}
@media (max-width: 414px) {
  html body .accrodionSection {
    max-width: 382px;
  }
}
@media (max-width: 411px) {
  html body .accrodionSection {
    max-width: 379px;
  }
}
@media (max-width: 375px) {
  html body .accrodionSection {
    max-width: 343px;
  }
}
@media (max-width: 360px) {
  html body .accrodionSection {
    max-width: 328px;
  }
}
@media (max-width: 320px) {
  html body .accrodionSection {
    max-width: 300px;
  }
}
html body .accrodionSection .buttons-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 823px) {
  html body .accrodionSection .buttons-holder {
    display: flex;
    align-items: unset;
    justify-content: unset;
  }
  html body .accrodionSection .buttons-holder .btn-group {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 823px;
    width: 100%;
  }
  html body .accrodionSection .buttons-holder .btn-group .btn {
    width: 100%;
  }
}
html body .meetTheTeam__hr {
  width: 100%;
}
@media (max-width: 767px) {
  html body .meetTheTeam__hr {
    width: 50%;
  }
}
html body .meetTheTeam {
  font-size: 2rem;
}
@media (max-width: 991px) {
  html body .meetTheTeam {
    font-size: 1.5rem;
  }
}
html body .pilotUpload .__img {
  width: 100%;
}
@media (max-width: 767px) {
  html body .pilotUpload .__img {
    padding-bottom: 40px;
  }
}
html body .pilotUpload .btn-holder {
  margin-top: -200px;
}
@media (max-width: 991px) {
  html body .pilotUpload .btn-holder {
    margin-top: -150px;
  }
}
html body .video-player {
  width: 100%;
}
html body .custom-list li {
  font-size: 1.5rem;
}
html body .custom-card-img {
  height: 200px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  html body .custom-card-img {
    height: 175px;
  }
}
@media (max-width: 991px) {
  html body .custom-card-img {
    height: 145px;
  }
}
@media (max-width: 767px) {
  html body .custom-card-img {
    height: 180px;
  }
}
html body .custom-card-img.__small {
  height: 100px;
}
html body .carousel .control-arrow,
html body .carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
html body .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
html body .carousel .control-arrow:before,
html body .carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}
html body .carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}
html body .carousel .control-prev.control-arrow {
  left: 0;
}
html body .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
html body .carousel .control-next.control-arrow {
  right: 0;
}
html body .carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}
html body .carousel {
  position: relative;
  width: 100%;
}
html body .carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html body .carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
html body .carousel .carousel {
  position: relative;
}
html body .carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
html body .carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
html body .carousel .thumbs {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
html body .carousel .thumb {
  -webkit-transition: border 0.15s ease-in;
  -moz-transition: border 0.15s ease-in;
  -ms-transition: border 0.15s ease-in;
  -o-transition: border 0.15s ease-in;
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
html body .carousel .thumb.selected,
html body .carousel .thumb:hover {
  border: 3px solid #333;
  padding: 2px;
}
html body .carousel .thumb img {
  vertical-align: top;
}
html body .carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
html body .carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
html body .carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
html body .carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in;
}
html body .carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
html body .carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
html body .carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
html body .carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column;
}
html body .carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
html body .carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
html body .carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #000;
}
html body .carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
html body .carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
html body .carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
html body .carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 100%;
}
@media (min-width: 960px) {
  html body .carousel .control-dots {
    bottom: 0;
  }
}
html body .carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
html body .carousel .control-dots .dot.selected,
html body .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
html body .carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
html body .carousel:hover .slide .legend {
  opacity: 1;
}
html body .mainCarousel {
  max-height: 520px !important;
}
html body .mainCarousel .caro-legend {
  max-width: 25% !important;
  min-width: 196px !important;
  background: whitesmoke !important;
  color: black !important;
  opacity: 1 !important;
  font-size: 1rem !important;
  top: 55% !important;
  bottom: auto !important;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: white;
  padding-bottom: 0 !important;
}
.homepage-caro {
  grid-row-start: 1;
  grid-row-end: span 5;
  grid-column-start: 1;
  grid-column-end: span 3;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}
@media (max-width: 767px) {
  .homepage-caro {
    grid-column-end: span 4;
  }
}
.homepage-caro img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.__wrapper-homepage {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 1;
  grid-row-end: span 6;
}
.__wrapper--footer {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row: 7;
}
.aerial-two-col-grid {
  display: grid;
  grid-template-columns: auto 25%;
  grid-template-rows: auto auto;
}
.aerial-two-col-grid .__text {
  grid-column-start: 1;
  grid-row-start: 1;
}
.aerial-two-col-grid .__btn {
  grid-column-start: 2;
  grid-row-start: 1;
  width: auto;
  height: auto;
  align-self: center;
  justify-self: right;
}
.aerial-two-col-grid .__picture {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  width: 100%;
  height: auto;
  align-self: center;
  justify-self: center;
  text-align: center;
}
.aerial-two-col-grid--left {
  grid-template-columns: 25% auto;
}
@media (max-width: 576px) {
  .aerial-two-col-grid {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
  .aerial-two-col-grid .__text {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
  }
  .aerial-two-col-grid .__btn {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    justify-self: center;
  }
  .aerial-two-col-grid .__picture {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
  }
  .aerial-two-col-grid .__picture img {
    width: 200px;
  }
}
hr.branded {
  width: 25%;
  border-color: red;
  border-width: 0.5rem;
  border-color: #0db14b;
}
hr.branded {
  width: 25%;
  border-color: red;
  border-width: 0.5rem;
  border-color: #0db14b;
}
hr.branded--left {
  margin-left: 0;
}
.ResponsiveImages img {
  width: 100%;
  height: auto;
}
.image-credit {
  position: relative;
  bottom: 35px;
  left: 10px;
  padding: 5px;
  border-radius: 5px;
  border-color: white;
  transition: all 0.25s linear;
}
.image-credit a {
  transition: all 0.25s linear;
}
.image-credit:hover {
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  color: white;
}
.image-credit:hover a {
  color: white;
}
@media (max-width: 991px) {
  .image-credit {
    bottom: 0;
    left: 0;
  }
  .image-credit:hover {
    background: none;
    border: none;
    color: black;
  }
  .image-credit:hover a {
    color: black;
  }
}
.row-images-uas {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 5%;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.row-images-uas img {
  width: 100%;
  height: auto;
}
.limit-fw-img img {
  width: 100%;
  height: auto;
  max-width: 1200px;
}
.uas-project-row img {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin-top: 0.5rem;
}
.uas-news .__uas-news {
  margin-bottom: 0;
}
.uas-news a {
  padding-left: 2rem;
  font-style: italic;
  margin-bottom: 1rem;
  display: block;
}
